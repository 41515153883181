import Grid from "@mui/material/Grid2";
import React, { forwardRef, useEffect, useState } from "react";
import { 
  Box, 
  Button, 
  Card, 
  CardContent, 
  Checkbox, 
  Dialog, 
  FormControlLabel, 
  Slide, 
  Table, 
  TableBody, 
  TableCell, 
  TableRow, 
  Typography 
} from "@mui/material";
import "./OrderConfirmModal.css";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function OrderConfirmModal({ open, orderData, selectedSubRecipes, confirmType, handleClose }) {

  const [guestPrice, setGuestPrice] = useState(orderData?.guestPrice || 0.00);
  const facilityMealPrice = orderData?.facilityMealPrice || 0.00;
  const addlCharges = orderData?.order?.additionalCharges || 0.00; //custom charge
  const otherCharges = orderData?.order?.orderCharges?.reduce((acc, curr) => acc += curr?.price, 0); //facility level charges
  const menuPriceString = `$${(Math.max(guestPrice, facilityMealPrice) + addlCharges + otherCharges).toFixed(2)}`;
  const [originalData, setOriginalData] = useState({});
  const [noChargeItems, setNoChargeItems] = useState([]);
  const [noPriceItems, setNoPriceItems] = useState([]); //items without price don't get "No Charge" checkbox

  useEffect(() => {
    if (orderData) {
      setOriginalData(() => structuredClone(orderData));
      const noChargeMenu = orderData?.menuItems?.filter((item) => !item?.guestPrice) || [];
      const noChargeAlt = orderData?.alternateItems?.filter((item) => !item?.guestPrice) || [];
      setNoChargeItems(() => [...noChargeMenu, ...noChargeAlt])
      const noPriceMenu = orderData?.menuChoices?.filter((item) => !item?.guestPrice) || [];
      const noPriceAlt = orderData?.alternateChoices?.filter((item) => !item?.guestPrice) || [];
      setNoPriceItems(() => [...noPriceMenu, ...noPriceAlt]);
    }
  }, [orderData]);

  const handleNoChargeClick = (item, type) => {
    const idx = noChargeItems?.findIndex((removed) => removed.itemId === item.itemId);

    if (idx >= 0) {
      
      item.guestPrice = orderData[type].find((recipe) => recipe.recipeId === item.itemId)?.guestPrice || 0.00;
      setGuestPrice((prev) => prev + (item.guestPrice ?? 0.00));
      
      setNoChargeItems((prev) => {
        const noChargeCopy = [...prev];
        noChargeCopy.splice(idx, 1);
        return noChargeCopy;
      });
    } else {
      setGuestPrice((prev) => prev - (item.guestPrice ?? 0.00));
      setNoChargeItems((prev) => [...prev, item]);
      item.guestPrice = 0.00;
    }
  }

  const renderMenuItems = () => {
    const menuItems = [];

    orderData.menuItems?.forEach((item, idx) => {
      const menuChoice = orderData.menuChoices?.find((choice) => choice.recipeId === item.itemId);
      const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.itemId) || [];

      menuItems.push(
        <TableRow key={`${item.itemId}-${idx}`} className="tableRow">  
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{menuChoice?.recipeName}{item?.specialNote ? ' - ' + item.specialNote : null}</Typography>
          </TableCell>

          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{item.quantity}</Typography>
          </TableCell>

          <TableCell 
            sx={{
              alignSelf: "center", 
              textAlign: "center", 
              borderBottom: "none", 
              paddingX: ".25rem !important"
            }}
            onClick={(ev) => ev.preventDefault()}
          >
            {!noPriceItems.some((noPrice) => noPrice.recipeId === item.itemId) &&
              <FormControlLabel
                sx={{
                  marginX: "0px !important",
                  "& .MuiTypography-root": { fontSize: ".875rem" },
                  "& .MuiButtonBase-root.MuiCheckbox-root": { padding: ".25rem" }
                }}
                control={
                  <Checkbox size="small"
                    checked={noChargeItems.some((removed) => removed.itemId === item.itemId)}
                    onClick={() => handleNoChargeClick(item, 'menuChoices')}
                  />
                }
                label="No Charge"
                labelPlacement="top"
              />
            }
          </TableCell>
        </TableRow>
      );

      if (item?.allergyNote) {
        subRecipes.splice(0, 0, {recipeId: -1, recipeName: item.allergyNote});
      }

      if (item?.removedItems?.length) {
        const subMapping = item.removedItems.map((removed, idx) => {
          return {
            recipeId: -2 - idx,
            recipeName: `Remove ${removed.text}`,
            isAllergyNote: true
          }
        });
        
        subRecipes.splice(0, 0, ...subMapping);
      }

      subRecipes.forEach((sub, idx) => {
        menuItems.push(
          <TableRow key={`${sub.recipeId}-${idx}`} className="priceRow">
            <TableCell sx={{alignSelf: "center", paddingLeft: "4rem !important", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.recipeName}</Typography>
            </TableCell>
            <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.quantity}</Typography>
            </TableCell>
          </TableRow>
        );
      })
    });

    return menuItems;
  }

  const renderAltItems = () => {
    const altItems = [];

    orderData.alternateItems?.forEach((item, idx) => {
      const menuChoice = orderData.alternateChoices?.find((choice) => choice.recipeId === item.itemId);
      const subRecipes = selectedSubRecipes?.filter((sub) => sub.parentRecipeId === item.itemId) || [];
      altItems.push(
        <TableRow key={`${item.itemId}-${idx}`} className="tableRow">  
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{menuChoice?.recipeName}{item?.specialNote ? ' - ' + item.specialNote : null}</Typography>
          </TableCell>

          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{item?.quantity}</Typography>
          </TableCell>

          <TableCell 
            sx={{
              alignSelf: "center", 
              textAlign: "center", 
              borderBottom: "none", 
              paddingX: ".25rem !important"
            }}
            onClick={(ev) => ev.preventDefault()}
          >            
            {!noPriceItems.some((noPrice) => noPrice.recipeId === item.itemId) &&
              <FormControlLabel
                sx={{
                  marginX: "0px !important",
                  "& .MuiTypography-root": { fontSize: ".875rem" },
                  "& .MuiButtonBase-root.MuiCheckbox-root": { padding: ".25rem" }
                }}
                control={
                  <Checkbox size="small"
                    checked={noChargeItems.some((removed) => removed.itemId === item.itemId)}
                    onClick={() => handleNoChargeClick(item, 'alternateChoices')}
                  />
                }
                label="No Charge"
                labelPlacement="top"
              />
            }
          </TableCell>
        </TableRow>
      );

      if (item?.allergyNote) {
        subRecipes.splice(0, 0, {recipeId: -1, recipeName: item.allergyNote});
      }

      if (item?.removedItems?.length) {
        const subMapping = item.removedItems.map((removed, idx) => {
          return {
            recipeId: -2 - idx,
            recipeName: `Remove ${removed.text}`,
            isAllergyNote: true
          }
        });
        
        subRecipes.splice(0, 0, ...subMapping);
      }

      subRecipes.forEach((sub, idx) => {
        altItems.push(
          <TableRow key={`${sub.recipeId}-${idx}`} className="priceRow">
            <TableCell sx={{alignSelf: "center", paddingLeft: "4rem !important", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.recipeName}</Typography>
            </TableCell>
            <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
              <Typography variant="body1" gutterBottom>{sub?.quantity}</Typography>
            </TableCell>
          </TableRow>
        );
      })
    });

    return altItems;
  }

  const renderPriceBreakdown = () => {
    const priceList = [];

    priceList.push(
      <TableRow key="facilityMealPrice" className="priceRow">
        <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
          <Typography variant="body1" gutterBottom>{`${orderData?.mealName?.split('-')?.[0]?.trim()}`}</Typography>
        </TableCell>
        <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
          <Typography variant="body1" gutterBottom>{`$${(Math.max(guestPrice, facilityMealPrice)).toFixed(2)}`}</Typography>
        </TableCell>
      </TableRow>
    );
    

    orderData?.order?.orderCharges?.forEach((charge, idx) => {
      priceList.push(
        <TableRow key={`facCharge-${idx}`} className="priceRow">
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{`${charge?.chargeTypeId === 6 ? `${charge.chargeTypeText}`: `${charge.chargeTypeName}`}`}</Typography>
          </TableCell>
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{`$${charge?.price?.toFixed(2)}`}</Typography>
          </TableCell>
        </TableRow>
      );
    });

    if (addlCharges) {
      priceList.push(
        <TableRow key={`addlCharge`} className="priceRow">
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{`${orderData?.order?.additionalChargeNote}`}</Typography>
          </TableCell>
          <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
            <Typography variant="body1" gutterBottom>{`$${addlCharges.toFixed(2)}`}</Typography>
          </TableCell>
        </TableRow>

      );
    }

    priceList.push(        
      <TableRow key="totalPrice" className="priceRow">
      <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
        <Typography variant="body1" fontWeight="bold" gutterBottom>{`Total`}</Typography>
      </TableCell>
      <TableCell sx={{alignSelf: "center", borderBottom: "none"}}>
        <Typography variant="body1" fontWeight="bold" gutterBottom>{`${menuPriceString}`}</Typography>
      </TableCell>
    </TableRow>
    );

    return priceList;
  }

  const handleSubmit = () => {
    orderData.guestPrice = Math.max(guestPrice, facilityMealPrice);
    handleClose(true);
  }

  const beforeClose = () => {
    orderData.alternateItems = [...originalData.alternateItems];
    orderData.menuItems = [...originalData.menuItems];
    handleClose(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => beforeClose()}
      TransitionComponent={Transition}
    >
      <Box sx={{maxWidth: "550px", height: "auto", maxHeight: "calc(100vh - 70px) !important"}} className="paperContent customScrollbar" justifyContent="center" alignItems="flex-start" padding={3}>
        <Typography variant="h6" gutterBottom textAlign="center">Are You Sure?</Typography>
        {!!orderData?.menuItems?.length && <Card sx={{marginBottom: "1rem"}}>
          <CardContent>
            <Typography variant="h5" gutterBottom textAlign="left">Menu Items</Typography>
            <Table>
              <TableBody>
                {renderMenuItems()}
              </TableBody>
            </Table>
          </CardContent>
        </Card>}

        {!!orderData?.alternateItems?.length && <Card sx={{marginBottom: "1rem"}}>
          <CardContent>
            <Typography variant="h5" gutterBottom textAlign="left">Alternate Items</Typography>
            <Table>
              <TableBody>
                {renderAltItems()}
              </TableBody>
            </Table>
          </CardContent>
        </Card>}

        {orderData?.order?.note && (
          <Card sx={{marginBottom: "1rem"}}>
            <CardContent>
              <Typography width="100%" variant="h5" textAlign="left">Note</Typography>
              <Grid sx={{display: "flex"}} justifyContent="flex-start" paddingX={2}>
                <Typography variant="body1">{orderData.order.note}</Typography>
              </Grid>
            </CardContent>
          </Card>
        )}

        <Card>
          <CardContent>
            <Grid container justifyContent="space-between">
              <Typography width="100%" variant="h5" textAlign="left">Meal Price</Typography>
              <Table>
                <TableBody>
                  {renderPriceBreakdown()}
                </TableBody>
              </Table>
            </Grid>
          </CardContent>
        </Card>

        <Grid container justifyContent="center" paddingY={3} paddingX={1}>
          <Button
            sx={{marginRight: ".5rem", width: "calc(50% - .5rem)"}}
            variant="contained"
            onClick={() => handleSubmit()}
          >
            {confirmType}
          </Button>
          <Button
            sx={{width: "calc(50% - .5rem)"}}
            variant="contained"
            onClick={() => beforeClose()}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Dialog>
  )
}