import { AppBar, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectTable({ open, tables, itemSelected, title, enableAll}) {

  const handleClose = () => {
    itemSelected();
  }

  const renderTables = () => {
    const uiArray = [];

    if (enableAll) {
      uiArray.push(
        <ListItemButton key={"listAll"} onClick={() => itemSelected('all')}>
          <ListItemText fontWeight="bold" primary={"All"} />
        </ListItemButton>
      );
      uiArray.push(<Divider key={"dividerAll"} />);
    }

    tables?.forEach((table, idx) => {
      uiArray.push(
        <ListItemButton key={`list${table?.id}`} onClick={() => itemSelected(table?.name)}>
          <ListItemText fontWeight="bold" primary={`${table?.name}`} />
        </ListItemButton>
      );

      if (idx < tables?.length - 1) {
        uiArray.push(<Divider key={`divider${table?.id}`} />)
      }
    });

    return uiArray;
  } 

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" color="inherit" sx={{marginLeft: ".25rem"}}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {renderTables()}
        </List>

      </Dialog>
  );
}