import { AppBar, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectMeal({ open, pendingMeals, itemSelected, title}) {

  const handleClose = () => {
    itemSelected();
  }

  const renderMeals = () => {
    const uiArray = [];

    pendingMeals?.forEach((meal, idx) => {
      uiArray.push(
        <ListItemButton key={`list${meal.id}`} onClick={() => itemSelected(meal.id, `${meal?.mealName} (${dayjs(meal?.diningDate).format('dddd [-] MM/DD/YYYY')})`)}>
          <ListItemText fontWeight="bold" primary={`${meal?.mealName} (${dayjs(meal?.diningDate).format('dddd [-] MM/DD/YYYY')})`} />
        </ListItemButton>
      );

      if (idx < pendingMeals?.length - 1) {
        uiArray.push(<Divider key={`divider${meal.id}`} />)
      }
    });

    return uiArray;
  } 

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" color="inherit" sx={{marginLeft: ".25rem"}}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {renderMeals()}
        </List>

      </Dialog>
  );
}