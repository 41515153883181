import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, MenuItem, Slide, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import { LocalizationProvider, MobileDateTimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BaseSelect from "../baseForm/BaseSelect";
import dayjs from "dayjs";
import "./EditSetupModal.css";
import { useUpdateMealMutation } from "store/apis/MealsApi";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function EditSetupModal({ open, meal, availableMeals, facilityId, handleClose }) {

  const [mealOptions, setMealOptions] = useState([]);
  const [seasonOptions, setSeasonOptions] = useState([]);
  const [menuOptions, setMenuOptions] = useState([]);
  const [dayOptions, setDayOptions] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all",
  });

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = methods;

  const [
    updateMeal,
    { isSubmitting: isSubmittingMeal, isSuccess: isSuccessMeal },
  ] = useUpdateMealMutation();

  useEffect(() => {
    if (availableMeals && meal) {

      setMealOptions(() => availableMeals?.meals?.map((meal) => {
        return { id: meal.id, label: meal.name }
      }));

      setSeasonOptions(() => availableMeals?.seasons?.map((season) => {
        return { id: season.id, label: season.name }
      }));

      setMenuOptions(() => availableMeals?.seasons?.find((season) => season.id === meal?.seasonId)?.menus?.map((menu) => {
        return { id: menu.id, label: menu.name}
      }));
      const daysInDefaultMenu = availableMeals?.seasons?.find((season) => season.id === meal?.seasonId)?.menus?.find((menu) => menu.id === meal.menuId)?.daysInMenu;
      setDayOptions(() => [...Array(daysInDefaultMenu).keys()].map(i => {
        return {id: ++i, label: `${i}`}
      }));

      setValue('seasonId', meal?.seasonId);
      setValue('mealId', meal?.mealId);
      setValue('menuId', meal?.menuId);
      setValue('day', meal?.day);
      setValue('diningDate', new Date(meal?.diningDate));
    }
  }, [availableMeals, meal]);

  useEffect(() => {
    if (!isSubmittingMeal && isSuccessMeal) {
      handleClose();
    }
  }, [isSubmittingMeal, isSuccessMeal]);

  const renderSeasons = () => {
    const seasonList = [];
    availableMeals?.seasons?.forEach((season) => {
      seasonList.push(
        <MenuItem key={season.id} value={season}>{season.name}</MenuItem>
      );
    });

    return seasonList;
  }

  const handleSeasonChange = (season) => {
    const newSeason = availableMeals?.seasons?.find((s) => s.id === season?.id);
    const newSeasonMenu = newSeason?.menus?.find((menu) => menu?.id === getValues("menuId"));

    setMenuOptions(() => newSeason?.menus?.map((menu) => {
      return { id: menu.id, label: menu.name};
    }));

    setValue('menuId', newSeasonMenu ? newSeasonMenu?.id : null);
    setValue('seasonId', season.id);
  }

  const renderMeals = () => {
    const mealList = [];
    availableMeals?.meals?.forEach((meal) => {
      mealList.push(
        <MenuItem key={meal.id} value={meal.id}>{meal.name}</MenuItem>
      );
    });

    return mealList;
  }

  const renderMenus = () => {
    const menuList = [];

    if (getValues()?.season) {
      getValues()?.season.menus?.forEach((menu) => {
        menuList.push(
          <MenuItem key={menu.id} value={menu}>{menu.name}</MenuItem>
        );
      });
    }

    return menuList;
  }

  const handleMenuChange = (menu) => {
    // when changed menu day range falls short of previously selected day, reset
    const selectedMenu = availableMeals?.seasons?.find((season) => season.id === getValues()?.seasonId)?.menus?.find((m) => m.id === menu.id);
    
    setDayOptions(() => [...Array(selectedMenu.daysInMenu).keys()].map(i => {
      return {id: ++i, label: `${i}`}
    }));    

    if (selectedMenu?.daysInMenu < getValues()?.day) {
      setValue('day', 1);
    }

    setValue('menuId', menu.id);
  }

  const renderDays = () => {
    const dayList = [];
    const numberOfDays = getValues()?.menu?.daysInMenu;

    if (numberOfDays > 0) {
      for (let i = 1; i <= numberOfDays; i++) {
        dayList.push(
          <MenuItem key={i} value={i}>{i}</MenuItem>
        );
      }
    }

    return dayList;
  }

  const onSubmit = (data) => {
    // make UTC adjustments for DB write from payload
    const hours = data.diningDate.getHours();
    const minutes = data.diningDate.getMinutes();

    data.diningDate?.setUTCHours(hours, minutes, 0, 0);

    updateMeal({
      ...data, 
      id: meal.id,
      facilityId: facilityId
    });
  }

  const errorMessage = useMemo(() => {
    if (errors?.diningDate?.message == 'minutesStep') {
      return "Time must end in 00, 15, 30, or 45";
    }
    
    return "";
  }, [errors?.diningDate]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
    >
      <Box>
        <Typography 
          variant="h5" 
          textAlign="center" 
          color="#fff" 
          sx={{ 
            margin: "0 0 .5rem", 
            padding: ".25rem", 
            display: "block", 
            width: "100%", 
            backgroundColor: "var(--tealColor)"
          }}
        >
          Edit Queued Meal
        </Typography>
        {!!meal.ordersTaken && 
          <Typography variant="subtitle1" textAlign="center" sx={{whiteSpace: "pre", lineHeight: "1.25"}}>
            Orders already taken for this meal. {'\n'}
            Only time can be changed.
          </Typography>
        }
        <div style={{padding: "1rem"}}>
          <Table sx={{gridTemplateColumns: "1fr 1fr"}}>
            <TableBody>
              <TableRow className="editRow">
                <TableCell className="editCell">
                  <Typography variant="subtitle1" textAlign="center" width="100%">Dining Date/Time:</Typography>
                </TableCell>
                <TableCell className="editCell">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDateTimePicker 
                      control={control}
                      name="diningDate"
                      id="diningDate"
                      sx={{" > div": {height: "2.5rem !important"}}} 
                      label="Select Date / Time" 
                      minutesStep={15}
                      closeOnSelect={false}
                      ampmInClock={true}
                      minDate={dayjs()}
                      defaultValue={dayjs(meal?.diningDate)}
                      onChange={(value) => setValue('diningDate', new Date(value))}
                      slotProps={{
                        textField: {
                          helperText: errorMessage
                        }
                      }}
                      onError={(error) => {
                        if (error?.length) {
                          setError('diningDate', {type: "custom", message: error?.toString()});
                          
                        } else {
                          clearErrors('diningDate');
                        }
                      }}
                    />
                  </LocalizationProvider>
                </TableCell>
              </TableRow>

              <TableRow className="editRow">
                <TableCell className="editCell">
                  <Typography variant="subtitle1" textAlign="center">Season:</Typography>
                </TableCell>
                <TableCell className="editCell">
                  <BaseSelect
                    id={"seasonId"}
                    name={"seasonId"}
                    fullWidth
                    control={control}
                    options={seasonOptions}
                    onChange={(event, value) => handleSeasonChange(value)}
                    disableClearable={true}
                    size="small"
                    disabled={!!meal.ordersTaken}
                    >
                    {renderSeasons()}
                  </BaseSelect>
                </TableCell>
              </TableRow>

              <TableRow className="editRow">
                <TableCell className="editCell">
                  <Typography variant="subtitle1" textAlign="center">Menu:</Typography>
                </TableCell>
                <TableCell className="editCell">
                  <BaseSelect
                    id={"menuId"}
                    name={"menuId"}
                    fullWidth
                    control={control}
                    errors={errors}
                    options={menuOptions}
                    disableClearable={true}
                    size="small"
                    disabled={!!meal.ordersTaken}
                    onChange={(event, value) => handleMenuChange(value)}
                    >
                    {renderMenus()}
                  </BaseSelect>
                </TableCell>
              </TableRow>
              

              <TableRow className="editRow">
                <TableCell className="editCell">
                  <Typography variant="subtitle1" textAlign="center">Meal:</Typography>
                </TableCell>
                <TableCell className="editCell">
                  <BaseSelect
                    id={"mealId"}
                    name={"mealId"}
                    control={control}
                    errors={errors}
                    fullWidth
                    size="small"
                    options={mealOptions}
                    disableClearable={true}
                    disabled={!!meal.ordersTaken}
                    >
                    {renderMeals()}
                  </BaseSelect>
                </TableCell>
              </TableRow>

              <TableRow className="editRow">
                <TableCell className="editCell">
                  <Typography variant="subtitle1" textAlign="center">Menu Day:</Typography>
                </TableCell>
                <TableCell className="editCell">
                  <BaseSelect
                    id={"day"}
                    name={"day"}
                    fullWidth
                    control={control}
                    errors={errors}
                    options={dayOptions}
                    disableClearable={true}
                    size="small"
                    disabled={!!meal.ordersTaken}
                    > 
                    {renderDays()}
                  </BaseSelect>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </Box>

      <Box width="100%" display="flex" justifyContent="flex-end" padding="0 1rem 1rem 0">
        <Button sx={{backgroundColor: "var(--orangeColor)", color: "#000", marginRight: ".5rem"}} variant="contained" onClick={handleClose}>Cancel</Button>
        <Button sx={{width: "fit-content"}} variant="contained" onClick={handleSubmit(onSubmit)}>
          Save
        </Button>
      </Box>
    </Dialog>
  )
}