import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mealName: "",
  guestInfo: {
    guestName: "",
    paymentDone: null, // 0,1,2,3 for No Charge/Payment Received, Bill to Resident, Bill to Employee
    selectedResident: null, //residentID to tie order to
    selectedGuestType: null // 1, 2, 3 for Guest/Employee/Other respectively
  },
  menuInfo: null,
  lastMealSelected: {
    setupId: null,
    mealName: null
  },
  orderWorkflow: null,
  lastTableFilter: null,
  lastWingFilter: null,
}

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    //just for title bar
    setSelectedMeal: (state, action) => {
      state.mealName = action.payload;
    },
    setGuestInfo: (state, action) => {
      state.guestInfo = {...action.payload};
    },
    //for storage and retrieval on held/submitted order edits
    setMenuInfo: (state, action) => {
      state.menuInfo = {...action.payload};
    },
    setLastMealSelected: (state, action) => {
      state.lastMealSelected = {...action.payload};
    },
    setOrderWorkflow: (state, action) => {
      state.orderWorkflow = action.payload;
    },
    setLastTableFilter: (state, action) => {
      state.lastTableFilter = action.payload;
    },
    setLastWingFilter: (state, action) => {
      state.lastWingFilter = action.payload;
    }
  }
})

export const { 
  setSelectedMeal, 
  setGuestInfo, 
  setMenuInfo,
  setLastMealSelected,
  setOrderWorkflow,
  setLastTableFilter,
  setLastWingFilter,
} = OrderSlice.actions;
export const selectActiveMeals = (state) => state?.order?.activeMeals;
export const selectResidentInfo = (state) => state?.order?.residentInfo;
export const selectMealName = (state) => state?.order?.mealName;
export const selectMenuInfo = (state) => state?.order?.menuInfo;
export const selectGuestInfo = (state) => state?.order?.guestInfo;
export const selectLastMealSelected = (state) => state?.order?.lastMealSelected;
export const selectOrderWorkflow = (state) => state?.order?.orderWorkflow;
export const selectLastTableFilter = (state) => state?.order?.lastTableFilter;
export const selectLastWingFilter = (state) => state?.order?.lastWingFilter;

export default OrderSlice.reducer;