import React, { useEffect } from "react";
import { usePrintSummaryMutation, useReprintOrderMutation } from "store/apis/OrdersApi";

export function OrderDetailContent({ lastSelectedRow, groupBySubmittedOrders, submittedOrderById, updateOrders }) {

  const [
    reprintOrder,
    {isFetching: isReprinting, isSuccess: isReprintSuccess}
  ] = useReprintOrderMutation();

  const handleReprint = (orderId) => {
    reprintOrder(orderId);
  }

  useEffect(() => {
    if (isReprintSuccess && !isReprinting) {
      updateOrders();
    }
  }, [isReprinting, isReprintSuccess]);

  const [
    printSummary,
    {isFetching: isPrinting, isSuccess: isPrintingSuccess} 
  ] = usePrintSummaryMutation();

  const handlePrintSummary = async (orderId) => {
    printSummary(orderId)
  }

  useEffect(() => {
    if (isPrintingSuccess && !isPrinting) {
      updateOrders();
    }
  }, [isPrinting, isPrintingSuccess])

  const checkForDietTextureSeparator = () => {
    return (submittedOrderById.diet && submittedOrderById.dietTexture);
  }

  const checkForDietOtherSeparator = () => {
    return ((submittedOrderById.diet && submittedOrderById.dietTexture && submittedOrderById.dietOther) ||
            (submittedOrderById.diet && !submittedOrderById.dietTexture && submittedOrderById.dietOther) ||
            (!submittedOrderById.diet && submittedOrderById.dietTexture && submittedOrderById.dietOther));
  }

  if (!lastSelectedRow) {
    return (<div> </div>);
  }

  if (!groupBySubmittedOrders || !groupBySubmittedOrders?.length) { return; }

  const selectedOrder = groupBySubmittedOrders.find((order) => order.orderId === lastSelectedRow.orderId);

  if (!selectedOrder) { return; }

  if (!submittedOrderById) {
    return (<div> </div>);
  }

  const renderSelectedOrderItems = (items) => {
    return items.map((item, idx) => {
      const subItems = [...item.subItems];

      if (item.allergyNote?.length) {
        subItems.splice(0, 0, {description: item.allergyNote});
      }

      if (item.removedItems?.length) {
        const subMapping = item.removedItems.map((removed) => {
          return {
            description: `Remove ${removed.text}`,
          }
        });
        
        subItems.splice(0, 0, ...subMapping);
      }

      return (
        <div key={`${idx}-${item.description}`}>
          <div className="detail-flex orderDetailsTableBorder">
            <div className="flex-9 orderDetailsTableFields"><div className="detail-info">{item.description}{item.specialNote ? ' - ' + item.specialNote : null}</div></div>
            <div className="flex-3 orderDetailsTableFields"><div className="detail-info">{item.quantity}</div></div>
          </div>
          { subItems.map((sub, idx) => (
              <div key={`sub-${idx}-${sub.description}`} className="detail-flex orderDetailsTableBorder">
                <div className="flex-9 orderDetailsTableFields"><div style={{ marginLeft: '50px' }}  className="detail-info">{sub.description}</div></div>
                <div className="flex-3 orderDetailsTableFields"><div className="detail-info">{sub.quantity}</div></div>
              </div>
            ))
          }
        </div>
      );
    })
  }

  return (
    <div key={submittedOrderById?.orderId} className="orderDetailsWrapper">
      <div className="orderDetailsContainer">
        <div className="orderDetails"> <h5 className="orderDetailsHeading">Resident&nbsp;</h5> <div className="detail-info">{submittedOrderById.residentName}</div> </div>
        <div className="orderDetails"> <h5 className="orderDetailsHeading">DiningArea&nbsp;</h5> <div className="detail-info">{submittedOrderById.diningArea}</div> </div>
        <div className="orderDetails"> <h5 className="orderDetailsHeading">Table&nbsp;</h5> <div style={{justifyContent: "center !important"}} className="detail-info">{submittedOrderById.tableName}</div> </div>
        <div className="orderDetails"><span><h5 className="orderDetailsHeading">Diet&nbsp;</h5></span><span className="detail-info">{submittedOrderById.diet}
          {checkForDietTextureSeparator() && '/'}{submittedOrderById.dietTexture}
          {checkForDietOtherSeparator() && '/'}{submittedOrderById.dietOther}</span></div>
        <div className="orderDetails"> <h5 className="orderDetailsHeading">Room #&nbsp;</h5> <div className="detail-info">{submittedOrderById.roomNumber}</div> </div>
      </div>
      <div>
        <div className="detail-flex">
          <div className="flex-9"><h5 className="orderDetailsHeading">Description</h5></div>
          <div className="flex-3"><h5 className="orderDetailsHeading">Quantity</h5></div>
        </div>
        {renderSelectedOrderItems(submittedOrderById.items)}
        <button className="detail-btn" onClick={() => handleReprint(submittedOrderById.orderId)}>
          Reprint
        </button>
        <button className="detail-btn" onClick={() => handlePrintSummary(submittedOrderById.orderId)}>
          Print Summary
        </button>
      </div>
    </div>
  );
}