import { AppBar, Dialog, Divider, IconButton, List, ListItemButton, ListItemText, Slide, Toolbar, Typography } from "@mui/material";
import React, { forwardRef } from "react";
import CloseIcon from "@mui/icons-material/Close";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectWing({ open, wings, itemSelected, title, enableAll}) {

  const handleClose = () => {
    itemSelected();
  }

  const renderWings = () => {
    const uiArray = [];

    if (enableAll) {
      uiArray.push(
        <ListItemButton key={"listAll"} onClick={() => itemSelected('all')}>
          <ListItemText fontWeight="bold" primary={"All"} />
        </ListItemButton>
      );
      uiArray.push(<Divider key={"dividerAll"} />);
    }

    wings?.forEach((wing, idx) => {
      uiArray.push(
        <ListItemButton key={`${wing}-${idx}`} onClick={() => itemSelected(wing)}>
          <ListItemText fontWeight="bold" sx={{textTransform: "capitalize"}} primary={`${wing}`} />
        </ListItemButton>
      );

      if (idx < wings?.length - 1) {
        uiArray.push(<Divider key={`divider${wing}`} />)
      }
    });

    return uiArray;
  } 

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}>
        <AppBar position="static">
          <Toolbar>
            <IconButton color="inherit" onClick={handleClose} aria-label="Close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" fontWeight="bold" color="inherit" sx={{marginLeft: ".25rem"}}>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <List>
          {renderWings()}
        </List>

      </Dialog>
  );
}