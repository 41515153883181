import { API_ENDPOINTS } from "../store.constants";
import { BaseApi } from "./BaseApi";

const { RESIDENTS } = API_ENDPOINTS;

export const ResidentsApi = BaseApi.injectEndpoints({
  endpoints: (builder) => ({
    getResidentsAll: builder.query({
      query: () => `/${RESIDENTS}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
            ...response.map(({ id }) => ({
              type: RESIDENTS,
              id,
            })),
            RESIDENTS
        ]
        : [RESIDENTS];
      }
    }),
    getResidentsByAreaId: builder.query({
      query: ({diningAreaId, setupId}) => `/${RESIDENTS}/ByDiningArea/${diningAreaId}/${setupId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
            {...response.map((resident) => {
              return {
                id: resident.id,
                label: resident.name
              }
            })},
            RESIDENTS
        ]
        : [RESIDENTS];
      }
    }),
    getResidentInfoById: builder.query({
      query: ({residentId, setupId}) => `/${RESIDENTS}/ResidentDetails/${residentId}/${setupId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{type: RESIDENTS, id}]
    }),
    getResidentActiveMealsById: builder.query({
      query: (residentId) => `/${RESIDENTS}/ActiveMeals/${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (result, error, id) => [{type: RESIDENTS, id}]

    }),
    getResidentTableSelection: builder.query({
      query: (residentId) => `/${RESIDENTS}/TableSelection/${residentId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          {type: RESIDENTS, id: response},
          RESIDENTS
        ]
        : [RESIDENTS];
      }
    }),
    getResidentAllergyByRecipe: builder.query({
      query: ({residentId, recipeId}) => `/${RESIDENTS}/AllergiesByRecipe/${residentId}/${recipeId}`,
      transformResponse: (response) => response?.data,
      providesTags: (response) => {
        return response
        ? [
          {type: RESIDENTS, id: response},
          RESIDENTS
        ] 
        : [RESIDENTS]
      }
    })
  })
});

export const {
  useGetResidentsAllQuery,
  useGetResidentsByAreaIdQuery,
  useGetResidentInfoByIdQuery,
  useGetResidentActiveMealsByIdQuery,
  useGetResidentTableSelectionQuery,
  useGetResidentAllergyByRecipeQuery
} = ResidentsApi;