import { Box, Dialog, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { EditSkeleton } from "components/admin/admin.overlays";
import React, { useEffect, useState } from "react";
import { useGetResidentInfoByIdQuery } from "store/apis/ResidentsApi";
import { ApiFetchImage } from "utils/ApiCall";
import profileImg from "../../../assets/imgs/profile_img.svg";
import { DEFAULT_EMPTY } from "store/store.constants";
import { useGetMealAddOnsQuery } from "store/apis/MealsApi";

export default function ResidentInfo({ open, showPhoto, handleInfoClose, residentId, setupId }) {

  const [photo, setPhoto] = useState(null);
  
  const {
    data: residentInfo,
    isFetching: fetchingResident,
  } = useGetResidentInfoByIdQuery(
    {residentId, setupId},
    { refetchOnMountOrArgChange: true, skip: !residentId || !setupId }
  );

  const {
    data: mealAddOn,
    isFetching: fetchingAddons
  }= useGetMealAddOnsQuery(
    { 
      residentId: residentId, 
      setupId: setupId, 
      isGuest: false
    },
    { skip: !residentId || !setupId }
  );

  const isLoading = fetchingResident || fetchingAddons;
  
  const fetchPhoto = () => {
    ApiFetchImage(
      // eslint-disable-next-line no-undef
      `${process.env.REACT_APP_BASE_URL}/Residents/Photo/${residentId}`
    ).then((res) => {
      if (res) {
        let reader = new FileReader();
        reader.onload = function(){
          const image = reader.result;
          setPhoto(image);
        }
        reader.readAsDataURL(res);
      } else {
        setPhoto(null);
      }
    }).catch((error) => {
      console.error(error);
      setPhoto(null);
    });
  }

  useEffect(() => {
    if (showPhoto) {
      fetchPhoto();
    }
  }, [showPhoto, residentId]);

  const buildResidentDiet = () => {
    const validDietStrings = [];

    if (!DEFAULT_EMPTY.includes(residentInfo?.diet)) {
      validDietStrings.push(residentInfo.diet);
    }

    if (!DEFAULT_EMPTY.includes(residentInfo?.dietTexture) && residentInfo.dietTexture !== "Regular") {
      validDietStrings.push(residentInfo.dietTexture);
    }   

    if (!DEFAULT_EMPTY.includes(residentInfo?.dietOther)) {
      validDietStrings.push(residentInfo.dietOther);
    }

    return validDietStrings.join(" | ");
  }

  return (
    <Dialog
      open={open}
      onClose={handleInfoClose}
      PaperProps={{
        sx: { width: "50%", 
              height: "auto", 
              minWidth: "400px"}
      }}
    >
      { isLoading ? (
          <EditSkeleton />
        ) : 
        (
          <>
            <Box
              component="div"
              display="block"
              flexWrap="nowrap"
              alignItems="flex-end"
              sx={{
                background: "#3e6281",
                padding: ".5rem",
                borderRadius: "var(--borderRadius)",
                boxShadow: ".125rem .25rem .25rem .0625rem rgba(0, 0, 0, .35) !important"
              }}
            >
              <Typography variant="h5" fontWeight="bold" textTransform="uppercase" color="#FFF" alignSelf="flex-start">
                {residentInfo && residentInfo.name}
              </Typography>
              {showPhoto && photo && (
                <Box component="div" id="infoImgBox">
                  <Box
                    component="img"
                    src={photo ? photo : profileImg}
                    alt="Profile Image"
                    sx={{
                      borderRadius: "var(--borderRadius) !important",
                      width: "5.25rem",
                      height: "5.25rem",
                      objectFit: "contain",
                    }}
                  />
                </Box>
              )}
              <Box display="flex" flexWrap="nowrap" justifyContent={showPhoto && photo ? "flex-start" : "flex-end"}>
                <Typography 
                  variant="subtitle2"
                  sx={{
                    color: "white !important",
                    display: "inline",
                    marginRight: ".5rem",
                    fontWeight: "bold",
                  }}
                  >
                  {residentInfo && residentInfo.roomNumber && ("ROOM: " + residentInfo.roomNumber)}
                </Typography>
                <Typography 
                  variant="subtitle2"
                  sx={{
                    color: "white !important",
                    display: "inline",
                    fontWeight: "bold",
                  }}
                  >
                  {residentInfo && residentInfo.bed && ("BED: " + residentInfo.bed)}
                </Typography>
              </Box>
            </Box>
            <Table sx={{display: "grid", fontSize: ".8125rem", margin: "1rem", width: "calc(100% - 2rem)"}}>
              <TableBody>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Diet</TableCell>
                  <TableCell 
                    className="contentCell" 
                    sx={{paddingRight: showPhoto && photo ? "6rem !important": "1rem"}}
                  >
                    { buildResidentDiet() }
                  </TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Liquid Consistency</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.liquidConsistency }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Fluid Restriction</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.fluidRestrictions }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Dislikes</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.dislikes }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Allergies</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.allergies }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Meals AddOn</TableCell>
                  <TableCell className="contentCell">{!!mealAddOn?.length && mealAddOn.join(", ") }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Adaptive Equipment</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.adaptiveEquipment }</TableCell>
                </TableRow>
                
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Dining Area</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.diningArea }</TableCell>
                </TableRow>
                <TableRow className="infoRow">
                  <TableCell id="labelCell">Notes</TableCell>
                  <TableCell className="contentCell">{ residentInfo && residentInfo.notes }</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </>
        )}
    </Dialog>
  )
}