import { loginRequest } from "../authConfig";
import { msalInstance } from "../index";
import { appInsights } from "./ApplicationInsightsService";

export async function ApiCall(url, requestType, data, accessToken) {
  let options;
  const headers = new Headers();

  if (typeof data === "string") {
    headers.append("Content-Type", "application/json");
  }

  const optionsBuilder = (requestType, data, headers) => {
    data
      ? (options = {
          method: requestType,
          headers,
          body: data,
        })
      : (options = {
          method: requestType,
          headers,
        });
  };

  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  optionsBuilder(requestType, data, headers);

  //TODO: have this just return headers and use that for fetch
  return await fetch(url, options).then(async (response) => {
    if (response?.ok) {
      // deletes don't return anything other than the status code 204
      if (response.status === 204) {
        return true;
      } else {
        const result = await response.json();
        return result;
      }
    } else if (response.status === 401) {
      console.error("User is not authenticated.");
      appInsights.trackException({
        error: new Error("User is not authenticated"),
      });
    } else if (response.status == 404) {
      return new Error("No image available");
    } else {
      const err = await response.json();
      console.error("Error: ", response.status, err.messages[0]);
      appInsights.trackException({
        error: new Error(
          `API Error Status (${response.status}): ${err.messages[0]}`
        ),
      });
      throw new Error(err.messages[0]);
    }
  });
}

export async function ApiFetchImage(url, accessToken) {
  let options;
  const headers = new Headers();

  options = {
    method: "GET",
    headers,
  };

  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error(
        "No active account! Verify a user has been signed in and setActiveAccount has been called."
      );
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account,
    });
    accessToken = response.accessToken;
  }

  const bearer = `Bearer ${accessToken}`;
  headers.append("Authorization", bearer);

  //TODO: have this just return headers and use that for fetch
  return await fetch(url, options).then(async (response) => {
    if (response?.ok) {
      // deletes don't return anything other than the status code 204
      if (response.status === 204) {
        return true;
      } else {
        const result = await response.blob();

        if (
          result.type === "image/jpeg" ||
          result.type === "image/png" ||
          result.type === "image/bmp" ||
          result.type === "image/gif" ||
          result.type === "image/tiff" ||
          result.type === "image/webp"
        ) {
          return result;
        } else {
          return null;
        }
      }
    } else if (response.status === 401) {
      console.error("User is not authenticated.");
      appInsights.trackException({
        error: new Error("User is not authenticated"),
      });
    } else if (response.status === 404) {

      return null;
    } else {
      const err = await response.json();
      console.error("Error: ", response.status, err.messages[0]);
      appInsights.trackException({
        error: new Error(
          `API Error Status (${response.status}): ${err.messages[0]}`
        ),
      });
      throw new Error(err.messages[0]);
    }
  });
}
