import { Box, Button, Dialog, FormControlLabel, IconButton, Slide, Switch, Toolbar, Typography } from "@mui/material";
import React, { forwardRef, useEffect, useState } from "react";
import BaseInput from "../baseForm/BaseInput";
import { useForm } from "react-hook-form";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/AddCircle";
import { useGetResidentAllergyByRecipeQuery } from "store/apis/ResidentsApi";
import { EditSkeleton } from "components/admin/admin.overlays";

const Transition = forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export default function AllergyOverrideModal({ residentId, recipeId, currentRemovedItems, currentNote, open, handleClose }) {

  const [removedItems, setRemovedItems] = useState([]);

  const methods = useForm({
    shouldUnregister: false,
    mode: "all"
  });
  
  const {
    control,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (currentRemovedItems?.length) {
      setRemovedItems(currentRemovedItems);
    }

    if (currentNote) {
      setValue("allergyNote", currentNote.replace("Allergy Note: ", ""));
    }
  }, [currentRemovedItems, currentNote]);

  const {
    data: allergyIngredients,
    isFetching: fetchingAllergies,
  } = useGetResidentAllergyByRecipeQuery({residentId: residentId, recipeId: recipeId},
    { skip: !residentId || !recipeId }
  );


  const onSubmit = (data) => {
    handleClose({removedItems: [...removedItems], note: data?.allergyNote})
  }

  useEffect(() => {
    trigger("allergyNote");
  }, [removedItems]);

  const handleAllergyRemoval = (event, ingredient) => {
    if (event?.target?.checked) {
      setRemovedItems((prev) => [...prev, ingredient]);
    } else {
      setRemovedItems((prev) => {
        const updatedList = [...prev];
        updatedList.splice(updatedList.findIndex((ing) => ing.id === ingredient.id), 1);
        return updatedList;
      });
    }
  }

  const checkRemovedItems = (ingredientText) => {
    return !!(removedItems?.findIndex((removed) => removed.text === ingredientText) > -1);
  }

  const renderAllergyIngredients = () => {
    const uiList = [];

    allergyIngredients?.forEach((ingredient) => {
      const isRemoved = !!removedItems?.find((removed) => removed.id === ingredient.id);

      uiList.push(
        <Box 
          key={ingredient.id} 
          component="div" 
          sx={{
            display: "flex", 
            flexWrap: "nowrap", 
            alignItems: "center", 
            justifyContent: "space-between", 
            marginBottom: ".25rem", 
            width: "100%"
          }}
        > 
          <Box component="div" sx={{width: "75%"}}>
            <Typography variant="body1">{ingredient.text}</Typography>
            <Typography fontSize=".675rem">{ingredient.description}</Typography>
          </Box>
          <FormControlLabel control={<Switch checked={checkRemovedItems(ingredient.text)} onClick={(event) => handleAllergyRemoval(event, ingredient)} />} label={isRemoved ? "Removed" : "Remove"}/>
        </Box>
      )
    });

    return uiList;
  }

  return (
    <Dialog
      open={open}
      onClose={() => handleClose({removedItems: [], note: null})}
      TransitionComponent={Transition}
      sx={{"& .MuiPaper-root": {border: "1px solid var(--tealColor)", minWidth: "250px", minHeight: "250px"} }}
      >
      <> 
      { fetchingAllergies ?
        (
          <EditSkeleton />
        )
        : 
        (
          <Box sx={{width: "450px"}}>
            <Box component="div" flexWrap="wrap" sx={{display: "flex", flex: "1 0 100%", justifyContent: "center"}}>
            <Toolbar 
              sx={{
                height: "2.75rem",  
                justifyContent: "flex-end", 
                minHeight: "2rem !important",
                marginTop: ".5rem", 
                padding: "2rem .75rem .5rem 1rem !important", 
                width: "100%"
              }}
            >
              <Typography variant="h5" fontWeight="bold">ALLERGY NOTICE</Typography>
              <IconButton sx={{marginLeft: "auto"}} color="inherit" onClick={() => handleClose({removedItems: [], note: null})} aria-label="Close">
                <CloseIcon sx={{color: "var(--redColor) !important"}} />
              </IconButton>
            </Toolbar>
              <Typography variant="body1" color="error" textAlign="left" fontWeight="bold" sx={{paddingLeft: "1rem", width: "100%", marginY: ".5rem"}}>
                Ingredient(s) flagged for allergy
                </Typography>
              <Box 
                component="div" 
                sx={{
                  display: "flex", 
                  flexWrap: "wrap", 
                  justifyContent: "flex-start", 
                  width: "100%", 
                  padding: ".5rem",
                  border: "1px solid var(--tealColor)",
                  borderRadius: "10px",
                  marginX: "1rem"
                }}
              >
                {renderAllergyIngredients()}
              </Box>
              <Box sx={{padding: "0 1rem", width: "100%"}}>
                <BaseInput 
                  control={control}
                  errors={errors}
                  id="allergyNote"
                  name="allergyNote"
                  label="Add note to entree to remove allergy"
                  multiline
                  variant="outlined"
                  validationProps={(removedItems?.length >= 1) ? {required: ""} : {
                    required: "Note is required when nothing selected for removal."
                  }}
                  rows="2"
                  fullWidth
                  required={!(removedItems?.length >= 1)}
                  margin="normal"
                />
              </Box>
              <Box
                component="div" 
                sx={{
                  display: "flex", 
                  flexWrap: "wrap", 
                  justifyContent: "flex-start", 
                  width: "100%", 
                  padding: ".5rem",
                  border: "1px solid var(--redColor)",
                  borderRadius: "10px",
                  marginX: "1rem"
                }}
              >
                <Typography variant="body2" gutterBottom color="var(--redColor)" fontWeight="bold">
                  You are choosing to ignore an allergy documented within this resident&apos;s MealCard profile.
                  Please note that by choosing this option, you are accepting the responsibility to monitor this allergy.
                  If needed, you can add additional communication in the &quot;special note&quot; section to ensure resident safety. 
                </Typography>
                <Typography variant="body2" color="#555555" gutterBottom>
                  It is the sole responsibility of the user to ensure all products ordered, prepared, and served comply with the resident&apos;s
                  needs regarding any potentially harmful allergens. Maintaining Allergy records associated with Automations and Integrations with
                  third party sites such as electronic medical records are the responsibility of the user.
                </Typography>
              </Box>
              <Box component="div" sx={{display: "flex", justifyContent: "flex-end", width: "100%", padding: "0 .5rem", margin: ".5rem"}}>
                <Button endIcon={<AddIcon/>} sx={{width: "fit-content", justifySelf: "center"}} variant="contained" onClick={handleSubmit(onSubmit)}>
                  Accept & Add
                </Button>
              </Box>
            </Box>
          </Box>
        )
      }
      </>
    </Dialog>
  )
}